import { Injectable, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';
import {
  IRouterHistory,
  IRouterHistoryService,
} from './router-history.service.interface';

@Injectable({
  providedIn: 'root',
})
export class RouterHistoryService implements IRouterHistoryService {
  private router = inject(Router);

  private readonly _previousUrl$ = new BehaviorSubject<null | string>(null);
  get previousUrl$() {
    return this._previousUrl$.asObservable();
  }
  private readonly _currentUrl$ = new BehaviorSubject<null | string>(null);
  get currentUrl$() {
    return this._currentUrl$.asObservable();
  }
  canGoBack$ = this._previousUrl$.pipe(map((previousUrl) => !!previousUrl));

  constructor() {
    this.router.events
      .pipe(
        filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (event: any) =>
            event instanceof NavigationStart || event instanceof NavigationEnd,
        ),
        scan<NavigationEnd | NavigationStart, IRouterHistory>(
          (acc, event) => {
            if (event instanceof NavigationStart) {
              // We need to track the trigger, id, and idToRestore from the NavigationStart events
              return {
                ...acc,
                event,
                trigger: event.navigationTrigger,
                id: event.id,
                idToRestore: event.restoredState?.navigationId,
              };
            }
            // NavigationEnd events
            const history = [...acc.history];
            let currentIndex = acc.currentIndex;

            // router events are imperative (router.navigate or routerLink) or undefined at first load
            if (!acc.trigger || acc.trigger === 'imperative') {
              const removeCurrentIndex =
                this.router.getCurrentNavigation()?.extras.replaceUrl ||
                this.router.getCurrentNavigation()?.extras.skipLocationChange;
              // remove all events in history that come after the current index (included if needed)
              history.splice(currentIndex + (removeCurrentIndex ? 0 : 1));
              // add the new event to the end of the history and set that as our current index
              history.push({ id: acc.id, url: event.urlAfterRedirects });
              currentIndex = history.length - 1;
            }
            // browser events (back/forward) are popstate events
            else if (acc.trigger === 'popstate') {
              // get the history item that references the idToRestore
              const idx = history.findIndex((x) => x.id === acc.idToRestore);
              // if found, set the current index to that history item and update the id
              if (idx > -1) {
                currentIndex = idx;
                history[idx].id = acc.id;
              } else {
                currentIndex = 0;
              }
            }
            return {
              ...acc,
              event,
              history,
              currentIndex,
            };
          },
          // @ts-expect-error: we don't pass any event as first value, but it will be set anyway
          {
            history: [],
            id: 0,
            idToRestore: 0,
            currentIndex: 0,
          },
        ),
        // filter out so we only act when navigation is done
        filter(({ event }) => event instanceof NavigationEnd),
      )
      .subscribe(({ history, currentIndex }) => {
        const previous = history[currentIndex - 1];
        const current = history[currentIndex];
        // update current and previous urls
        if (this._previousUrl$.getValue() !== previous?.url) {
          this._previousUrl$.next(previous?.url ?? null);
        }
        if (this._currentUrl$.getValue() !== current?.url) {
          this._currentUrl$.next(current?.url ?? null);
        }
      });
  }

  canGoBack() {
    return !!this._previousUrl$.getValue();
  }
}
