import { Pipe, PipeTransform } from '@angular/core';
import { DefaultUrlSerializer, Params } from '@angular/router';

@Pipe({
  name: 'urlToRouterLink',
  standalone: true,
})
export class UrlToRouterLinkPipe implements PipeTransform {
  transform(value: string): {
    fragment: null | string;
    queryParams: Params;
    routerLink: string;
  } {
    const urlTree = new DefaultUrlSerializer().parse(value);
    const { fragment, queryParams } = urlTree;
    urlTree.queryParams = {};
    urlTree.fragment = null;
    const routerLink = urlTree.toString();
    return { routerLink, fragment, queryParams };
  }
}
