import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { setDefaultOptions } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { PREFERRED_LANG_PREF_KEY } from '../constants';
import { DocumentHeadService } from './document-head.service';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  private documentHeadService = inject(DocumentHeadService);
  private translocoService = inject(TranslocoService);

  private initialized = false;
  langChanges$ = this.translocoService.langChanges$;

  constructor() {
    this.langChanges$.pipe(takeUntilDestroyed()).subscribe((lang) => {
      this.documentHeadService.setLocale(lang === 'fr' ? 'fr_FR' : 'en_EN');
      setDefaultOptions({ locale: lang === 'fr' ? fr : enUS });
    });
  }

  async init() {
    if (this.initialized) {
      return;
    }

    const browserLang = (
      Capacitor.isNativePlatform()
        ? await Device.getLanguageCode()
        : getBrowserLang()
    ) as string;
    let preferredLang = this.translocoService.getDefaultLang();

    try {
      const { value: storedPreferredLang } = await Preferences.get({
        key: PREFERRED_LANG_PREF_KEY,
      });
      if (storedPreferredLang) {
        preferredLang = storedPreferredLang;
      }
    } catch {
      if (
        browserLang &&
        // available langs are set as array of strings in transloco root module
        (this.translocoService.getAvailableLangs() as string[]).includes(
          browserLang,
        )
      ) {
        preferredLang = browserLang;
      }
    }

    if (this.translocoService.getActiveLang() !== preferredLang) {
      this.translocoService.setActiveLang(preferredLang);
    }

    this.initialized = true;
  }

  getAvailableLangs() {
    return this.translocoService.getAvailableLangs() as string[];
  }

  getActiveLang() {
    return this.translocoService.getActiveLang();
  }

  setActiveLang(lang: string) {
    try {
      Preferences.set({
        key: PREFERRED_LANG_PREF_KEY,
        value: lang,
      });
    } catch {
      // NOOP
    }

    return this.translocoService.setActiveLang(lang);
  }
}
