import { ViewportScroller } from '@angular/common';
import { Injectable, InjectionToken, OnDestroy, inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription, asyncScheduler } from 'rxjs';
import { filter, observeOn, scan } from 'rxjs/operators';
import {
  IRouteScrollStrategy,
  IRouterScrollService,
  IScrollPositionRestore,
  RouteScrollBehaviour,
} from './router-scroll.service.interface';

const defaultViewportKey = `defaultViewport`;
const customViewportKey = `customViewport`;

export const ROUTER_SCROLL_SERVICE = new InjectionToken<{
  [key: string]: unknown;
}>('routerScrollService');

@Injectable()
export class RouterScrollService implements IRouterScrollService, OnDestroy {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly viewportScroller = inject(ViewportScroller);

  private readonly scrollPositionRestorationSubscription: Subscription | null;

  /**
   * Queue of strategies to add
   */
  private addQueue: IRouteScrollStrategy[] = [];
  /**
   * Queue of strategies to add for onBeforeNavigation
   */
  private addBeforeNavigationQueue: IRouteScrollStrategy[] = [];
  /**
   * Queue of strategies to remove
   */
  private removeQueue: string[] = [];
  /**
   * Registered strategies
   */
  private routeStrategies: IRouteScrollStrategy[] = [];
  /**
   * Whether the default viewport should be scrolled if/when needed
   */
  private scrollDefaultViewport = true;
  /**
   * Custom viewport to scroll if/when needed
   */
  private customViewportToScroll: HTMLElement | null = null;

  constructor() {
    this.viewportScroller.setHistoryScrollRestoration('manual');
    this.viewportScroller.setOffset([0, 200]);

    const scrollPositionRestore$ = this.router.events.pipe(
      filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (event: any) =>
          event instanceof NavigationStart || event instanceof NavigationEnd,
      ),
      // Accumulate the scroll positions
      scan((acc, event) => {
        const positions: IScrollPositionRestore['positions'] = {
          ...acc.positions, // Keep the previously known positions
        };

        if (event instanceof NavigationStart && this.scrollDefaultViewport) {
          positions[`${event.id}-${defaultViewportKey}`] =
            this.viewportScroller.getScrollPosition();
        }

        if (event instanceof NavigationStart && this.customViewportToScroll) {
          positions[`${event.id}-${customViewportKey}`] =
            this.customViewportToScroll.scrollTop;
        }

        let child = this.activatedRoute.firstChild;
        while (child?.firstChild) {
          child = child.firstChild;
        }
        const routeData = child?.snapshot?.data;
        const currentNavigation = this.router.getCurrentNavigation();
        const navigationState = currentNavigation?.extras?.state;
        const fragment = currentNavigation?.extractedUrl?.fragment;

        const retVal: IScrollPositionRestore = {
          event,
          positions,
          trigger:
            event instanceof NavigationStart
              ? event.navigationTrigger
              : acc.trigger,
          idToRestore:
            (event instanceof NavigationStart &&
              event.restoredState &&
              event.restoredState.navigationId + 1) ||
            acc.idToRestore,
          routeData,
          navigationState,
          fragment,
        };

        return retVal;
      }),
      filter(
        (scrollPositionRestore: IScrollPositionRestore) =>
          !!scrollPositionRestore.trigger,
      ),
      observeOn(asyncScheduler),
    );

    this.scrollPositionRestorationSubscription =
      scrollPositionRestore$.subscribe(
        (scrollPositionRestore: IScrollPositionRestore) => {
          const existingStrategy = this.routeStrategies.find(
            (strategy) =>
              scrollPositionRestore.event.url.indexOf(strategy.partialRoute) >
              -1,
          );

          const existingStrategyWithKeepScrollPositionBehavior =
            existingStrategy &&
            existingStrategy.behaviour === RouteScrollBehaviour.KEEP_POSITION;

          const routeDataWithKeepScrollPositionBehavior =
            scrollPositionRestore.routeData?.scrollBehavior ===
              RouteScrollBehaviour.KEEP_POSITION ||
            scrollPositionRestore.navigationState?.scrollBehavior ===
              RouteScrollBehaviour.KEEP_POSITION;

          const shouldKeepScrollPosition =
            existingStrategyWithKeepScrollPositionBehavior ||
            routeDataWithKeepScrollPositionBehavior;

          const shouldImmediatelyScrollToTop =
            scrollPositionRestore.routeData?.scrollBehavior ===
              RouteScrollBehaviour.GO_TO_TOP ||
            scrollPositionRestore.navigationState?.scrollBehavior ===
              RouteScrollBehaviour.GO_TO_TOP;

          if (shouldImmediatelyScrollToTop) {
            setTimeout(() => {
              if (this.scrollDefaultViewport) {
                this.viewportScroller.scrollToPosition([0, 0]);
              }
              if (this.customViewportToScroll) {
                this.customViewportToScroll.scrollTop = 0;
              }
            });
          }

          if (scrollPositionRestore.event instanceof NavigationEnd) {
            this.processRemoveQueue(this.removeQueue);

            // Was this an imperative navigation? This helps determine if we're moving forward through a routerLink, a back button click, etc
            // Reference: https://www.bennadel.com/blog/3533-using-router-events-to-detect-back-and-forward-browser-navigation-in-angular-7-0-4.htm
            const imperativeTrigger =
              (scrollPositionRestore.trigger &&
                'imperative' === scrollPositionRestore.trigger) ||
              false;

            // Should scroll to the top if
            // no strategy or strategy with behavior different than keep position
            // OR no route data or route data with behavior different than keep position
            // OR imperative
            // Reference: https://medium.com/javascript-everyday/angular-imperative-navigation-fbab18a25d8b

            // Decide whether we should scroll back to top or not
            const shouldScrollToTop = !imperativeTrigger
              ? false
              : !shouldKeepScrollPosition;

            setTimeout(() => {
              if (shouldScrollToTop) {
                if (this.scrollDefaultViewport) {
                  if (scrollPositionRestore.fragment) {
                    this.viewportScroller.scrollToAnchor(
                      scrollPositionRestore.fragment,
                    );
                  } else {
                    this.viewportScroller.scrollToPosition([0, 0]);
                  }
                }
                if (this.customViewportToScroll) {
                  if (scrollPositionRestore.fragment) {
                    const fragmentScrollTop =
                      this.customViewportToScroll.querySelector(
                        '#' + scrollPositionRestore.fragment,
                      )?.scrollTop ?? 0;
                    this.customViewportToScroll.scrollTop = fragmentScrollTop;
                  } else {
                    this.customViewportToScroll.scrollTop = 0;
                  }
                }
              } else if (!imperativeTrigger) {
                const position =
                  scrollPositionRestore.positions[
                    `${scrollPositionRestore.idToRestore}-${defaultViewportKey}`
                  ];

                if (position !== undefined) {
                  if (this.scrollDefaultViewport) {
                    this.viewportScroller.scrollToPosition(
                      position as [number, number],
                    );
                  }

                  if (this.customViewportToScroll) {
                    this.customViewportToScroll.scrollTop = position as number;
                  }
                }
              }
            });

            this.processRemoveQueue(
              this.addBeforeNavigationQueue.map(
                (strategy) => strategy.partialRoute,
              ),
              true,
            );
            this.processAddQueue(this.addQueue);
            this.addQueue = [];
            this.removeQueue = [];
            this.addBeforeNavigationQueue = [];
          } else {
            this.processAddQueue(this.addBeforeNavigationQueue);
          }
        },
      );
  }

  addStrategyOnceBeforeNavigationForPartialRoute(
    partialRoute: string,
    behaviour: RouteScrollBehaviour,
  ): void {
    this.addBeforeNavigationQueue.push({
      partialRoute: partialRoute,
      behaviour: behaviour,
      onceBeforeNavigation: true,
    });
  }

  addStrategyForPartialRoute(
    partialRoute: string,
    behaviour: RouteScrollBehaviour,
  ): void {
    this.addQueue.push({ partialRoute: partialRoute, behaviour: behaviour });
  }

  removeStrategyForPartialRoute(partialRoute: string): void {
    this.removeQueue.push(partialRoute);
  }

  setCustomViewportToScroll(viewport: HTMLElement): void {
    this.customViewportToScroll = viewport;
  }

  disableScrollDefaultViewport(): void {
    this.scrollDefaultViewport = false;
  }

  enableScrollDefaultViewPort(): void {
    this.scrollDefaultViewport = true;
  }

  processAddQueue(queue: IRouteScrollStrategy[]) {
    for (const partialRouteToAdd of queue) {
      const pos = this.routeStrategyPosition(partialRouteToAdd.partialRoute);
      if (pos === -1) {
        this.routeStrategies.push(partialRouteToAdd);
      }
    }
  }

  processRemoveQueue(queue: string[], removeOnceBeforeNavigation = false) {
    for (const partialRouteToRemove of queue) {
      const pos = this.routeStrategyPosition(partialRouteToRemove);
      if (
        !removeOnceBeforeNavigation &&
        pos > -1 &&
        this.routeStrategies[pos].onceBeforeNavigation
      ) {
        continue;
      }
      if (pos > -1) {
        this.routeStrategies.splice(pos, 1);
      }
    }
  }

  routeStrategyPosition(partialRoute: string) {
    return this.routeStrategies
      .map((strategy) => strategy.partialRoute)
      .indexOf(partialRoute);
  }

  ngOnDestroy(): void {
    if (this.scrollPositionRestorationSubscription) {
      this.scrollPositionRestorationSubscription.unsubscribe();
    }
  }
}
