import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  inject,
} from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  private nativeElement = inject(ElementRef).nativeElement;

  @Output() clickOutside = new EventEmitter<void>();

  @HostListener('document:click', ['$event'])
  public onClick({ target }: Event) {
    const clickedInside = this.nativeElement.contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
