import { EnvironmentInterface } from '../environment.interface';
import { sharedEnvironment } from './environment.shared';

export const environment: EnvironmentInterface = {
  ...sharedEnvironment,
  envName: 'production',
  production: true,
  baseUrl: 'https://www.ffbridge.fr',
  cmsLocalUrl: 'http://127.0.0.1:1337',
  cmsBaseUrl: 'https://cms-lancelot.ffbridge.fr',
  apiLocalUrl: 'http://lancelot-haproxy:15000',
  apiBaseUrl: 'https://api-lancelot.ffbridge.fr',
  mediaLibraryUrl: 'https://res.cloudinary.com/ffbridge/production',
  easiPublicUrl: 'https://public.ffbridge.fr',
  easiLicenseeUrl: 'https://licencie.ffbridge.fr',
  easiProUrl: 'https://metier.ffbridge.fr',
  educationSpaceUrl: 'https://formation.ffbridge.fr',
  appleAppStoreUrl: 'https://apps.apple.com/fr/app/ffbridge/id1593663414',
  googlePlayStoreUrl:
    'https://play.google.com/store/apps/details?id=fr.ffbridge.app',
  firebase: {
    apiKey: 'AIzaSyBjiLwewyM5PhXhfDSir5Cxvut0Yg4lYFQ',
    authDomain: 'lancelot-32bb2.firebaseapp.com',
    projectId: 'lancelot-32bb2',
    storageBucket: 'lancelot-32bb2.appspot.com',
    messagingSenderId: '1031840587278',
    appId: '1:1031840587278:web:9544f72f6747ba30cce65f',
    measurementId: 'G-P7BCJWDQ1M',
  },
  vapidKey:
    'BBrQ91LnPYlMAwOWFwHjDf1K_REJcoRyLSuVO4OegjB7lITgzOaH7Dme1GGLspy1DVxveV988H7anBpwFayWwLw',
  googleTagManagerId: 'GTM-MW5LNFT',
};
