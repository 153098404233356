import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { TextZoom } from '@capacitor/text-zoom';
import { TEXT_ZOOM_PREF_KEY } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class TextZoomService {
  isAvailable = Capacitor.isNativePlatform();

  constructor() {
    if (this.isAvailable) {
      this.init();
    }
  }

  async init() {
    const { value: storedZoomValue } = await Preferences.get({
      key: TEXT_ZOOM_PREF_KEY,
    });
    const { value: preferredZoomValue } = await TextZoom.getPreferred();
    TextZoom.set({
      value: storedZoomValue
        ? parseFloat(storedZoomValue)
        : undefined || preferredZoomValue || 1,
    });
  }

  async zoom(step: number) {
    const { value: currentZoomValue } = await TextZoom.get();
    const newZoomValue = currentZoomValue + step;
    TextZoom.set({ value: newZoomValue });
    Preferences.set({
      key: TEXT_ZOOM_PREF_KEY,
      value: newZoomValue.toString(),
    });
  }
}
