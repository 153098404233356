import { EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export function pauseWhileInactive<T>(isActive$: Observable<boolean>) {
  return (source$: Observable<T>) =>
    source$.pipe(
      switchMap(() =>
        isActive$.pipe(switchMap((isActive) => (isActive ? source$ : EMPTY))),
      ),
    );
}
