import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TUI_DATE_FORMAT } from '@taiga-ui/cdk';
import {
  TUI_ENGLISH_LANGUAGE,
  TUI_FRENCH_LANGUAGE,
  TUI_LANGUAGE,
} from '@taiga-ui/i18n';
import { map } from 'rxjs/operators';
import SwiperCore, { Autoplay } from 'swiper';

export const provideUiKit = (): EnvironmentProviders => {
  SwiperCore.use([Autoplay]);

  return makeEnvironmentProviders([
    {
      provide: TUI_LANGUAGE,
      deps: [TranslocoService],
      useFactory: (translocoService: TranslocoService) =>
        translocoService.langChanges$.pipe(
          map((lang) =>
            lang === 'fr' ? TUI_FRENCH_LANGUAGE : TUI_ENGLISH_LANGUAGE,
          ),
        ),
    },
    {
      provide: TUI_DATE_FORMAT,
      deps: [TranslocoService],
      useFactory: (translocoService: TranslocoService) =>
        translocoService.getActiveLang() === 'fr' ? 'DMY' : 'YMD',
    },
  ]);
};
