import { BreakpointObserver as AngularBreakpointObserver } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

const breakpoints = {
  xs: {
    begin: 0,
    end: '599.9px',
  },
  sm: {
    begin: '600px',
    end: '959.9px',
  },
  md: {
    begin: '960px',
    end: '1279.9px',
  },
  lg: {
    begin: '1280px',
    end: '1919.9px',
  },
  xl: {
    begin: '1920px',
    end: '4999.99px',
  },
} as const;

const overlappingGt = {
  gtXs: '600px',
  gtSm: '960px',
  gtMd: '1280px',
  gtLg: '1920px',
} as const;

const overlappingLt = {
  ltSm: '599.9px',
  ltMd: '959.9px',
  ltLg: '1279.9px',
  ltXl: '1919.9px',
} as const;

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserver {
  private breakpointObserver = inject(AngularBreakpointObserver);

  xs$!: Observable<boolean>;
  sm$!: Observable<boolean>;
  md$!: Observable<boolean>;
  lg$!: Observable<boolean>;
  xl$!: Observable<boolean>;
  gtXs$!: Observable<boolean>;
  gtSm$!: Observable<boolean>;
  gtMd$!: Observable<boolean>;
  gtLg$!: Observable<boolean>;
  ltSm$!: Observable<boolean>;
  ltMd$!: Observable<boolean>;
  ltLg$!: Observable<boolean>;
  ltXl$!: Observable<boolean>;

  constructor() {
    Object.keys(breakpoints).forEach(
      (bp) =>
        // @ts-expect-error: `${bp}$` does exist on BreakpointObserver
        (this[`${bp}$`] = this.breakpointObserver
          .observe(
            // @ts-expect-error: bp does exist on breakpoints
            `(min-width: ${breakpoints[bp].begin}) and (max-width: ${breakpoints[bp].end})`,
          )
          .pipe(
            map(({ matches }) => matches),
            distinctUntilChanged(),
          )),
    );
    Object.keys(overlappingGt).forEach(
      (bp) =>
        // @ts-expect-error: `${bp}$` does exist on BreakpointObserver
        (this[`${bp}$`] = this.breakpointObserver
          // @ts-expect-error: bp does exist on overlappingGt
          .observe(`(min-width: ${overlappingGt[bp]})`)
          .pipe(
            map(({ matches }) => matches),
            distinctUntilChanged(),
          )),
    );
    Object.keys(overlappingLt).forEach(
      (bp) =>
        // @ts-expect-error: `${bp}$` does exist on BreakpointObserver
        (this[`${bp}$`] = this.breakpointObserver
          // @ts-expect-error: bp does exist on overlappingLt
          .observe(`(max-width: ${overlappingLt[bp]})`)
          .pipe(
            map(({ matches }) => matches),
            distinctUntilChanged(),
          )),
    );
  }
}
