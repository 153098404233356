import { Directive, Input, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

@Directive({
  selector: '[disableLink]',
  standalone: true,
})
export class DisableLinkDirective {
  private link = inject(RouterLink);

  @Input() disableLink!: boolean;

  constructor() {
    // Save original method
    const onClick = this.link.onClick;

    // Replace method
    this.link.onClick = (...args) => {
      if (this.disableLink) {
        return false;
      } else {
        return onClick.apply(this.link, args);
      }
    };
  }
}
