import { Pipe, PipeTransform, inject } from '@angular/core';
import {
  NumberFormatOptions,
  TranslocoLocaleService,
} from '@ngneat/transloco-locale';

@Pipe({
  name: 'decimalWithUnit',
  standalone: true,
})
export class DecimalWithUnitPipe implements PipeTransform {
  private translocoLocaleService = inject(TranslocoLocaleService);

  transform(
    value: null | number | string | undefined,
    numberFormatOptions: NumberFormatOptions = {},
    unit = '',
  ): string {
    if (value === null || value === undefined) {
      return '—';
    }

    return `${this.translocoLocaleService.localizeNumber(
      value,
      'decimal',
      undefined,
      {
        minimumFractionDigits:
          value === 0 || (unit === '%' && value === 100) ? undefined : 2,
        ...numberFormatOptions,
      },
    )}${String.fromCharCode(160)}${unit}`;
  }
}
