import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Directive,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { BreakpointObserver } from '../cdk/breakpoint-observer';

const inputs = [
  'show.xs',
  'show.sm',
  'show.md',
  'show.lg',
  'show.xl',
  'show.ltSm',
  'show.ltMd',
  'show.ltLg',
  'show.ltXl',
  'show.gtXs',
  'show.gtSm',
  'show.gtMd',
  'show.gtLg',
  'hide.xs',
  'hide.sm',
  'hide.md',
  'hide.lg',
  'hide.xl',
  'hide.ltSm',
  'hide.ltMd',
  'hide.ltLg',
  'hide.ltXl',
  'hide.gtXs',
  'hide.gtSm',
  'hide.gtMd',
  'hide.gtLg',
];

const selector = `
  [show.xs], [show.sm], [show.md], [show.lg], [show.xl],
  [show.ltSm], [show.ltMd], [show.ltLg], [show.ltXl],
  [show.gtXs], [show.gtSm], [show.gtMd], [show.gtLg],
  [hide.xs], [hide.sm], [hide.md], [hide.lg], [hide.xl],
  [hide.ltSm], [hide.ltMd], [hide.ltLg], [hide.ltXl],
  [hide.gtXs], [hide.gtSm], [hide.gtMd], [hide.gtLg]
`;

// eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
@Directive({
  selector,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs,
  standalone: true,
})
export class ShowHideDirective implements OnChanges {
  private viewContainer = inject(ViewContainerRef);
  private templateRef = inject(TemplateRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly breakpointObserver = inject(BreakpointObserver);

  private _shouldShowByBreakpoint: Record<string, boolean> = {};

  onBreakPointMatch(bp: string, match: boolean) {
    let shouldShow = !this._shouldShowByBreakpoint[bp];
    if (match) {
      shouldShow = !shouldShow;
    }
    if (shouldShow) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
    this.changeDetectorRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach((key) => {
      if (inputs.indexOf(key) !== -1) {
        const inputKey = key.split('.');
        const bp = inputKey.slice(1).join('.');
        const inputValue = changes[key].currentValue;
        let shouldShow =
          inputValue !== ''
            ? inputValue !== 0
              ? coerceBooleanProperty(inputValue)
              : false
            : true;
        if (inputKey[0] === 'hide') {
          shouldShow = !shouldShow;
        }
        this._shouldShowByBreakpoint[bp] = shouldShow;
        if (changes[key].isFirstChange()) {
          // @ts-expect-error: `${bp}$` does exist on BreakpointObserverService
          this.breakpointObserver[`${bp}$`].subscribe(
            this.onBreakPointMatch.bind(this, bp),
          );
        }
      }
    });
  }
}
