import { Injectable, InjectionToken, inject } from '@angular/core';
import { EnvironmentInterface } from './environment.interface';

export const ENVIRONMENT = new InjectionToken<EnvironmentInterface>(
  'environment',
);

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private readonly environment: EnvironmentInterface | null = inject(
    ENVIRONMENT,
    { optional: true },
  );

  get<Key extends keyof EnvironmentInterface>(
    key: Key,
  ): EnvironmentInterface[Key] | undefined;
  get<Key extends keyof EnvironmentInterface, DefaultValue>(
    key: Key,
    defaultValue: DefaultValue,
  ): DefaultValue | EnvironmentInterface[Key];
  get<Key extends keyof EnvironmentInterface, DefaultValue>(
    key: Key,
    defaultValue?: DefaultValue,
  ): DefaultValue | EnvironmentInterface[Key] | undefined {
    return this.environment?.[key] || defaultValue;
  }
}
