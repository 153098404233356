import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import {
  EnvironmentProviders,
  Injectable,
  LOCALE_ID,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import {
  Translation,
  TranslocoLoader,
  TranslocoService,
  provideTransloco,
} from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { environment } from '@lancelot-frontend/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(
      `/assets/i18n/${lang}.json?nocache=${
        environment.commitSha || environment.distName
      }`,
    );
  }
}

class LocaleId extends String {
  private translocoService = inject(TranslocoService);
  override toString(): string {
    return this.translocoService.getActiveLang() === 'fr' ? 'fr-FR' : 'en-US';
  }

  override valueOf(): string {
    return this.toString();
  }
}

export const provideI18n = (): EnvironmentProviders => {
  registerLocaleData(localeFr);
  registerLocaleData(localeEn);

  return makeEnvironmentProviders([
    provideTransloco({
      config: {
        availableLangs: ['fr', 'en'],
        defaultLang: 'fr',
        fallbackLang: 'fr',
        missingHandler: {
          useFallbackTranslation: true,
          logMissingKey: !environment.production,
        },
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        fr: 'fr-FR',
        en: 'en-EN',
      },
    }),
    provideTranslocoMessageformat({
      locales: ['fr-FR', 'en-EN'],
    }),
    {
      provide: LOCALE_ID,
      useClass: LocaleId,
    },
  ]);
};
