import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Pipe({
  name: 'formatPhoneNumber',
  standalone: true,
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parse(value);
    return phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
  }
}
