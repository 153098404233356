import { DOCUMENT } from '@angular/common';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Subscription, from, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentHeadService implements OnDestroy {
  private document = inject(DOCUMENT);
  private titleService = inject(Title);
  private metaService = inject(Meta);
  private translocoService = inject(TranslocoService);

  private readonly _titleTranslationLoaded$ = new BehaviorSubject<boolean>(
    false,
  );
  private titleSubscription?: Subscription;
  private descriptionSubscription?: Subscription;

  constructor() {
    this.translocoService.events$
      .pipe(
        filter((e) => e.type === 'translationLoadSuccess' && !e.payload.scope),
        take(1),
      )
      .subscribe(() => {
        this._titleTranslationLoaded$.next(true);
      });
  }

  setTitle(t: Promise<string> | string): void {
    if (this.titleSubscription && !this.titleSubscription.closed) {
      this.titleSubscription.unsubscribe();
    }

    this.titleSubscription = this._titleTranslationLoaded$
      .pipe(filter((loaded) => loaded))
      .pipe(switchMap(() => (typeof t === 'string' ? of(t) : from(t))))
      .subscribe((title) => {
        const defaultTitle = this.translocoService.translate('title');

        const fullTitle =
          title === defaultTitle ? title : `${title} - ${defaultTitle}`;
        this.titleService.setTitle(fullTitle);
        this.metaService.updateTag({
          property: 'og:title',
          content: fullTitle,
        });
      });
  }

  setDescription(d: Promise<string> | string): void {
    if (this.descriptionSubscription && !this.descriptionSubscription.closed) {
      this.descriptionSubscription.unsubscribe();
    }

    this.descriptionSubscription = (
      typeof d === 'string' ? of(d) : from(d)
    ).subscribe((description) => {
      // first decode HTML special characters
      const textArea = this.document.createElement('textarea');
      textArea.innerHTML = description;
      const descriptionWithoutSpecialCharacers = textArea.value;
      // then replace line breaks and news paragraphs and titles with \n
      const descriptionWithoutBrs = descriptionWithoutSpecialCharacers.replace(
        /<br>|<\/p>|<\/h2>|<\/h3>|<\/h4>/g,
        '\n',
      );
      // then remove all remaining HTML tags
      const descriptionWithoutHtmlTags = descriptionWithoutBrs.replace(
        /<[^>]+>/g,
        '',
      );

      this.metaService.updateTag({
        property: 'og:description',
        content:
          descriptionWithoutHtmlTags.length > 190
            ? descriptionWithoutHtmlTags.substring(0, 190).concat('...')
            : descriptionWithoutHtmlTags,
      });
    });
  }

  setImage(url: string): void {
    this.metaService.updateTag({
      property: 'og:image',
      content: url,
    });
  }

  setVideo(url: string): void {
    this.metaService.updateTag({
      property: 'og:video',
      content: url,
    });
  }

  setUrl(url: string): void {
    this.metaService.updateTag({
      property: 'og:url',
      content: url,
    });
  }

  setLocale(locale: string): void {
    this.metaService.updateTag({
      property: 'og:locale',
      content: locale,
    });
  }

  ngOnDestroy() {
    if (this.titleSubscription && !this.titleSubscription.closed) {
      this.titleSubscription.unsubscribe();
    }
    if (this.descriptionSubscription && !this.descriptionSubscription.closed) {
      this.descriptionSubscription.unsubscribe();
    }
  }
}
