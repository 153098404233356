import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys',
  standalone: true,
})
export class ObjectKeys implements PipeTransform {
  transform(value: ArrayLike<unknown> | Record<string, unknown>) {
    return Object.keys(value);
  }
}

@Pipe({
  name: 'objectValues',
  standalone: true,
})
export class ObjectValues implements PipeTransform {
  transform<T>(value: { [s: string]: T } | ArrayLike<T>): T[] {
    return Object.values(value);
  }
}

@Pipe({
  name: 'objectEntries',
  standalone: true,
})
export class ObjectEntries implements PipeTransform {
  transform<T>(value: { [s: string]: T } | ArrayLike<T>): [string, T][] {
    return Object.entries(value);
  }
}
