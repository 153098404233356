import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { Browser } from '@capacitor/browser';

@Directive({
  selector: 'a[href]',
  standalone: true,
})
export class ExternalLinkDirective implements OnChanges {
  private platformId = inject(PLATFORM_ID);
  private nativeElement = inject(ElementRef).nativeElement;

  @HostBinding('attr.href') hrefAttr = '';
  @HostBinding('attr.target') targetAttr = '';
  @Input() href!: string;
  @Input() target = '_self';

  ngOnChanges() {
    this.hrefAttr = this.href;
    this.targetAttr = this.target;
  }

  private isLinkExternal() {
    return (
      isPlatformBrowser(this.platformId) &&
      !this.nativeElement.hostname.includes(location.hostname)
    );
  }

  @HostListener('click', ['$event']) onClick = (e: Event) => {
    if (this.href && this.isLinkExternal()) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      Browser.open({ url: this.href, windowName: this.target });
    }
  };
}
