import { ComponentRef, Directive } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

@Directive({
  selector: '[routeReuseLifecycle]',
  standalone: true,
})
export class RouteReuseLifeCycleDirective {
  constructor(private routerOutlet: RouterOutlet) {
    const originalAttach = this.routerOutlet.attach;

    this.routerOutlet.attach = (
      ref: ComponentRef<unknown>,
      activatedRoute: ActivatedRoute,
    ) => {
      originalAttach.bind(this.routerOutlet)(ref, activatedRoute);

      const instance = this.routerOutlet.component;

      if (
        instance &&
        'ngOnAttach' in instance &&
        typeof instance.ngOnAttach === 'function'
      ) {
        instance.ngOnAttach();
      }
    };

    const originalDetach = this.routerOutlet.detach;
    this.routerOutlet.detach = () => {
      const instance = this.routerOutlet.component;

      if (
        instance &&
        'ngOnDetach' in instance &&
        typeof instance.ngOnDetach === 'function'
      ) {
        instance.ngOnDetach();
      }

      return originalDetach.bind(this.routerOutlet)();
    };
  }
}

export interface OnAttach {
  ngOnAttach(): void;
}

export interface OnDetach {
  ngOnDetach(): void;
}
