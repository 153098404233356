import { EnvironmentInterface } from '../environment.interface';

// This file is automatically edited by set-shared-environment.ts script
export const sharedEnvironment: Pick<
  EnvironmentInterface,
  | 'axeptio'
  | 'commitSha'
  | 'distName'
  | 'facebook'
  | 'facility'
  | 'funbridge'
  | 'reCAPTCHA'
  | 'releaseName'
  | 'sentry'
> = {
  releaseName: '2.10.0',
  distName: '111',
  commitSha: '1c301a925e3ac052f2dccad73bc0772b057a6b24',
  facebook: {
    appId: '548639610362427',
  },
  reCAPTCHA: {
    siteKey: '6LefOCEfAAAAAO_B_A94aiiSBtydaEuTjUIHdjIp',
  },
  sentry: {
    webAppDSN:
      'https://0cccbe14f57046f486ff6de377c81a56@o33430.ingest.sentry.io/6053779',
    mobileAppDSN:
      'https://9e72026d9b5c4396a41aed9ad1e56ca6@o33430.ingest.sentry.io/6053734',
  },
  funbridge: {
    apiKey: 'b1b3f69b-174f-49cd-9360-98c2d59534c1',
    thirdParty: 'FFB',
  },
  facility: {
    appId: 'c9713f77-2e07-4dd9-948d-6c78b756061a',
  },
  axeptio: {
    clientId: '6155af970dfe6f58d74a0554',
  },
};
