import { PhoneNumberUtil } from 'google-libphonenumber';

export const upperFirst = (value: string): string =>
  value.slice(0, 1).toUpperCase() + value.slice(1);

export const isPhoneNumberMatch = (
  phone1: null | string | undefined,
  phone2: null | string | undefined,
) => {
  if (!phone1 && !phone2) {
    return true;
  }
  if (!phone1 || !phone2) {
    return false;
  }

  const phoneUtil = PhoneNumberUtil.getInstance();

  return [
    PhoneNumberUtil.MatchType.EXACT_MATCH,
    PhoneNumberUtil.MatchType.NSN_MATCH,
    PhoneNumberUtil.MatchType.SHORT_NSN_MATCH,
  ].includes(phoneUtil.isNumberMatch(phone1, phone2));
};
