import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@lancelot-frontend/environment';

const CLOUDINARY_URL = 'https://res.cloudinary.com';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private environmentService = inject(EnvironmentService);

  getImageUrlForAspectRatio(originalUrl: string, aspectRatio: number) {
    if (!originalUrl.includes(CLOUDINARY_URL)) {
      return originalUrl;
    }
    return originalUrl.replace(
      '/upload',
      `/upload/ar_${aspectRatio},c_fill,g_auto`,
    );
  }

  getImageUrlForLQIP(originalUrl: string) {
    if (!originalUrl.includes(CLOUDINARY_URL)) {
      return originalUrl;
    }
    return originalUrl.replace('/upload', `/upload/w_9`);
  }

  getOptimizedVideoUrl(originalUrl: string) {
    if (!originalUrl.includes(CLOUDINARY_URL)) {
      return originalUrl;
    }

    return originalUrl.replace('/upload', `/upload/q_auto,f_auto`);
  }

  getAvatarUrl(avatarPublicId: string) {
    if (/^https?:\/\//.test(avatarPublicId)) {
      return avatarPublicId;
    }

    const mediaLibraryUrl = this.environmentService.get('mediaLibraryUrl', '');
    const mediaLibraryFolder = mediaLibraryUrl.split('/').pop() || '';

    return `${mediaLibraryUrl.replace(
      mediaLibraryFolder,
      `w_200,h_200,c_fill,g_auto/${mediaLibraryFolder}`,
    )}/avatars/${avatarPublicId}`;
  }
}
