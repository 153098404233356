import { Pipe, PipeTransform } from '@angular/core';
import { upperFirst } from '../utils';

@Pipe({
  name: 'upperfirst',
  standalone: true,
})
export class UpperFirstPipe implements PipeTransform {
  transform = upperFirst;
}
