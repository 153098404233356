import type { FormatRelativeOptions } from 'date-fns';
import { Pipe, PipeTransform } from '@angular/core';

import { formatRelative } from 'date-fns';

@Pipe({
  name: 'relativeTime',
  standalone: true,
})
export class RelativeTimePipe implements PipeTransform {
  transform(
    value?: Parameters<typeof formatRelative>[0],
    baseDate: Parameters<typeof formatRelative>[1] = new Date(),
    options?: FormatRelativeOptions,
  ): string {
    if (value === null || value === undefined) {
      return '—';
    }

    return formatRelative(value, baseDate, options);
  }
}
