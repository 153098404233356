import { EnvironmentInterface } from '../environment.interface';
import { sharedEnvironment } from './environment.shared';

export const environment: EnvironmentInterface = {
  ...sharedEnvironment,
  envName: 'robot',
  production: true,
  baseUrl: 'https://lancelot-test.ffbridge.fr',
  cmsLocalUrl: 'https://cms-lancelot-test.ffbridge.fr',
  cmsBaseUrl: 'https://cms-lancelot-test.ffbridge.fr',
  apiLocalUrl: 'https://api-lancelot-test.ffbridge.fr',
  apiBaseUrl: 'https://api-lancelot-test.ffbridge.fr',
  mediaLibraryUrl: 'https://res.cloudinary.com/ffbridge/dev-recette-robot',
  easiPublicUrl: 'https://public-preprod.ffbridge.fr',
  easiLicenseeUrl: 'https://licencie-preprod.ffbridge.fr',
  easiProUrl: 'https://metier-preprod.ffbridge.fr',
  educationSpaceUrl: 'https://formation-preprod.ffbridge.fr',
  appleAppStoreUrl: 'https://apps.apple.com/fr/app/ffbridge/id1593663414',
  googlePlayStoreUrl:
    'https://play.google.com/store/apps/details?id=fr.ffbridge.app',
  firebase: {
    apiKey: 'AIzaSyC6PQTDsQUSVGDtLnPUf6RkQMNSJGnZerI',
    authDomain: 'lancelot-7f148.firebaseapp.com',
    projectId: 'lancelot-7f148',
    storageBucket: 'lancelot-7f148.appspot.com',
    messagingSenderId: '782223822524',
    appId: '1:782223822524:web:044dc310d82b1a7e3c4ab9',
    measurementId: 'G-BZ0K32MTXK',
  },
  vapidKey:
    'BLBQyBnFnVvphb_puxK3M5qc1SbYxAzcWG--Zpy9ts0j2cjEO2pbnPuLEwqfYugHMxvMnGvqHGgko692oRGV5_U',
};
