import { TuiCountryIsoCode } from '@taiga-ui/i18n/enums';
const TUI_FRENCH_LANGUAGE_ADDON_COMMERCE = {
  cardNumber: ['Number', 'Numéro de carte'],
  cardExpiry: ['Expiry', 'Date d"Expiration de la carte']
};
const TUI_FRENCH_LANGUAGE_ADDON_EDITOR = {
  colorSelectorModeNames: ['Couleur unie', 'Dégradé de couleur'],
  toolbarTools: {
    undo: 'Annuler',
    redo: 'Rétablir',
    font: 'Police',
    fontStyle: 'Style de police',
    fontSize: 'Taille de police',
    bold: 'Gras',
    italic: 'Italique',
    underline: 'Souligner',
    strikeThrough: 'Barrer',
    justify: 'Justifier',
    justifyLeft: 'Aligner à gauche',
    justifyCenter: 'Centrer',
    justifyRight: 'Justifier à droite',
    justifyFull: 'Justifier',
    list: 'Liste',
    indent: 'Indenter',
    outdent: 'Réduire le retrait',
    unorderedList: 'Liste à puces',
    orderedList: 'Liste numérotée',
    quote: 'Citation',
    foreColor: 'Couleur de texte',
    backColor: 'Couleur de fond',
    hiliteColor: 'Couleur de mise en évidence',
    clear: 'Effacer',
    link: 'Lien',
    attach: 'Joindre un fichier',
    tex: 'Insérer TeX',
    code: 'Code',
    image: 'Insérer une image',
    insertHorizontalRule: 'Insérer une règle horizontale',
    superscript: 'Exposant',
    subscript: 'Indice',
    insertTable: 'Insérer une table',
    insertGroup: 'Insérer un groupe',
    hiliteGroup: 'Hilite group',
    removeGroup: 'Supprimer le groupe',
    insertAnchor: 'Insérer une ancre',
    mergeCells: 'Fusionner les cellules',
    splitCells: 'Diviser les cellules',
    rowsColumnsManaging: 'Gestion des lignes et des colonnes',
    cellColor: 'Couleur de la cellule',
    setDetails: 'Accordéon',
    removeDetails: "Supprimer l'accordéon"
  },
  editorEditLink: {
    urlExample: 'exemple.com',
    anchorExample: 'ancre'
  },
  editorTableCommands: [['Insérer une colonne avant', 'Insérer une colonne après'], ['Insérer une ligne avant', 'Insérer une ligne après'], ['Supprimer la colonne', 'Supprimer la ligne']],
  editorCodeOptions: ['Code dans le texte', 'Bloc de code'],
  editorFontOptions: {
    small: 'Petite',
    large: 'Grande',
    normal: 'Normale',
    title: 'Titre',
    subtitle: 'Sous-titre'
  }
};
const TUI_FRENCH_LANGUAGE_ADDON_PREVIEW = {
  previewTexts: {
    rotate: 'Faire pivoter'
  },
  zoomTexts: {
    zoomOut: 'Zoomer',
    zoomIn: 'Dézoomer',
    reset: 'Réinitialiser'
  }
};
const TUI_FRENCH_LANGUAGE_ADDON_TABLE = {
  showHideText: 'Afficher/Cacher',
  paginationTexts: {
    pages: 'Pages',
    linesPerPage: 'Lignes par page',
    of: 'de'
  }
};
const TUI_FRENCH_LANGUAGE_COUNTRIES = {
  [TuiCountryIsoCode.AD]: 'Andorre',
  [TuiCountryIsoCode.AE]: 'Émirats arabes unis',
  [TuiCountryIsoCode.AF]: 'Afghanistan',
  [TuiCountryIsoCode.AG]: 'Antigua et Barbuda',
  [TuiCountryIsoCode.AI]: 'Anguilla',
  [TuiCountryIsoCode.AL]: 'Albanie',
  [TuiCountryIsoCode.AM]: 'Arménie',
  [TuiCountryIsoCode.AO]: 'Angola',
  [TuiCountryIsoCode.AR]: 'Argentine',
  [TuiCountryIsoCode.AT]: 'Autriche',
  [TuiCountryIsoCode.AU]: 'Australie',
  [TuiCountryIsoCode.AW]: 'Aruba',
  [TuiCountryIsoCode.AZ]: 'Azerbaïdjan',
  [TuiCountryIsoCode.BA]: 'Bosnie-Herzégovine',
  [TuiCountryIsoCode.BB]: 'Barbade',
  [TuiCountryIsoCode.BD]: 'Bangladesh',
  [TuiCountryIsoCode.BE]: 'Belgique',
  [TuiCountryIsoCode.BF]: 'Burkina Faso',
  [TuiCountryIsoCode.BG]: 'Bulgarie',
  [TuiCountryIsoCode.BH]: 'Bahrein',
  [TuiCountryIsoCode.BI]: 'Burundi',
  [TuiCountryIsoCode.BJ]: 'Bénin',
  [TuiCountryIsoCode.BL]: 'Saint-Barthélemy',
  [TuiCountryIsoCode.BM]: 'Bermudes',
  [TuiCountryIsoCode.BN]: 'Brunéi Darussalam',
  [TuiCountryIsoCode.BO]: 'Bolivie',
  [TuiCountryIsoCode.BQ]: 'Bonaire, Saint-Eustache et Saba',
  [TuiCountryIsoCode.BR]: 'Brésil',
  [TuiCountryIsoCode.BS]: 'Bahamas',
  [TuiCountryIsoCode.BT]: 'Bhoutan',
  [TuiCountryIsoCode.BW]: 'Botswana',
  [TuiCountryIsoCode.BY]: 'Bélarus',
  [TuiCountryIsoCode.BZ]: 'Bélize',
  [TuiCountryIsoCode.CA]: 'Canada',
  [TuiCountryIsoCode.CD]: 'République Démocratique du Congo (RDC)',
  [TuiCountryIsoCode.CF]: 'République centrafricaine',
  [TuiCountryIsoCode.CG]: 'Congo Brazzaville',
  [TuiCountryIsoCode.CH]: 'Suisse',
  [TuiCountryIsoCode.CI]: "Côte d'Ivoire",
  [TuiCountryIsoCode.CL]: 'Chili',
  [TuiCountryIsoCode.CM]: 'Cameroun',
  [TuiCountryIsoCode.CN]: 'Chine',
  [TuiCountryIsoCode.CO]: 'Colombie',
  [TuiCountryIsoCode.CR]: 'Costa Rica',
  [TuiCountryIsoCode.CU]: 'Cuba',
  [TuiCountryIsoCode.CV]: 'Cap-Vert',
  [TuiCountryIsoCode.CW]: 'Curaçao',
  [TuiCountryIsoCode.CY]: 'Chypre',
  [TuiCountryIsoCode.CZ]: 'République Tchèque',
  [TuiCountryIsoCode.DE]: 'Allemagne',
  [TuiCountryIsoCode.DJ]: 'Djibouti',
  [TuiCountryIsoCode.DK]: 'Danemark',
  [TuiCountryIsoCode.DM]: 'Dominique',
  [TuiCountryIsoCode.DO]: 'République Dominicaine',
  [TuiCountryIsoCode.DZ]: 'Algérie',
  [TuiCountryIsoCode.EC]: 'Équateur',
  [TuiCountryIsoCode.EE]: 'Estonie',
  [TuiCountryIsoCode.EG]: 'Égypte',
  [TuiCountryIsoCode.ER]: 'Érythrée',
  [TuiCountryIsoCode.ES]: 'Espagne',
  [TuiCountryIsoCode.ET]: 'Éthiopie',
  [TuiCountryIsoCode.FI]: 'Finlande',
  [TuiCountryIsoCode.FJ]: 'Fidji',
  [TuiCountryIsoCode.FK]: 'Iles Malouines/Falkland',
  [TuiCountryIsoCode.FM]: 'États fédérés de Micronésie',
  [TuiCountryIsoCode.FR]: 'France',
  [TuiCountryIsoCode.GA]: 'Gabon',
  [TuiCountryIsoCode.GB]: 'Royaume-Uni',
  [TuiCountryIsoCode.GD]: 'Grenade',
  [TuiCountryIsoCode.GE]: 'Géorgie',
  [TuiCountryIsoCode.GF]: 'Guyane',
  [TuiCountryIsoCode.GH]: 'Ghana',
  [TuiCountryIsoCode.GI]: 'Gibraltar',
  [TuiCountryIsoCode.GL]: 'Groenland',
  [TuiCountryIsoCode.GM]: 'Gambie',
  [TuiCountryIsoCode.GN]: 'Guinée',
  [TuiCountryIsoCode.GP]: 'Guadeloupe',
  [TuiCountryIsoCode.GQ]: 'Guinée équatoriale',
  [TuiCountryIsoCode.GR]: 'Grèce',
  [TuiCountryIsoCode.GT]: 'Guatemala',
  [TuiCountryIsoCode.GW]: 'Guinée-Bissau',
  [TuiCountryIsoCode.GY]: 'Guyana',
  [TuiCountryIsoCode.HK]: 'Hong Kong',
  [TuiCountryIsoCode.HN]: 'Honduras',
  [TuiCountryIsoCode.HR]: 'Croatie',
  [TuiCountryIsoCode.HT]: 'Haïti',
  [TuiCountryIsoCode.HU]: 'Hongrie',
  [TuiCountryIsoCode.ID]: 'Indonesia',
  [TuiCountryIsoCode.IE]: 'Irlande',
  [TuiCountryIsoCode.IL]: 'Israël',
  [TuiCountryIsoCode.IN]: 'Inde',
  [TuiCountryIsoCode.IQ]: 'Iraq',
  [TuiCountryIsoCode.IR]: 'Iran',
  [TuiCountryIsoCode.IS]: 'Islande',
  [TuiCountryIsoCode.IT]: 'Italie',
  [TuiCountryIsoCode.JM]: 'Jamaïque',
  [TuiCountryIsoCode.JO]: 'Jordanie',
  [TuiCountryIsoCode.JP]: 'Japon',
  [TuiCountryIsoCode.KE]: 'Kenya',
  [TuiCountryIsoCode.KG]: 'Kirghizistan',
  [TuiCountryIsoCode.KH]: 'Cambodge',
  [TuiCountryIsoCode.KM]: 'Comores',
  [TuiCountryIsoCode.KN]: 'Saint-Kitts-et-Nevis',
  [TuiCountryIsoCode.KP]: 'Corée du Nord',
  [TuiCountryIsoCode.KR]: 'Corée du Sud',
  [TuiCountryIsoCode.KW]: 'Koweït',
  [TuiCountryIsoCode.KY]: 'Iles Caïmans',
  [TuiCountryIsoCode.KZ]: 'Kazakhstan',
  [TuiCountryIsoCode.LA]: 'Lao',
  [TuiCountryIsoCode.LB]: 'Liban',
  [TuiCountryIsoCode.LC]: 'Sainte-Lucie',
  [TuiCountryIsoCode.LI]: 'Liechtenstein',
  [TuiCountryIsoCode.LK]: 'Sri Lanka',
  [TuiCountryIsoCode.LR]: 'Libéria',
  [TuiCountryIsoCode.LS]: 'Lesotho',
  [TuiCountryIsoCode.LT]: 'Lituanie',
  [TuiCountryIsoCode.LU]: 'Luxembourg',
  [TuiCountryIsoCode.LV]: 'Lettonie',
  [TuiCountryIsoCode.LY]: 'Libye',
  [TuiCountryIsoCode.MA]: 'Maroc',
  [TuiCountryIsoCode.MC]: 'Monaco',
  [TuiCountryIsoCode.MD]: 'Moldavie',
  [TuiCountryIsoCode.ME]: 'Monténégro',
  [TuiCountryIsoCode.MF]: 'Saint-Martin',
  [TuiCountryIsoCode.MG]: 'Madagascar',
  [TuiCountryIsoCode.MK]: 'Macédoine',
  [TuiCountryIsoCode.ML]: 'Mali',
  [TuiCountryIsoCode.MM]: 'Myanmar',
  [TuiCountryIsoCode.MN]: 'Mongolie',
  [TuiCountryIsoCode.MO]: 'Macao',
  [TuiCountryIsoCode.MQ]: 'Martinique',
  [TuiCountryIsoCode.MR]: 'Mauritanie',
  [TuiCountryIsoCode.MS]: 'Montserrat',
  [TuiCountryIsoCode.MT]: 'Malte',
  [TuiCountryIsoCode.MU]: 'Maurice',
  [TuiCountryIsoCode.MV]: 'Maldives',
  [TuiCountryIsoCode.MW]: 'Malawi',
  [TuiCountryIsoCode.MX]: 'Mexique',
  [TuiCountryIsoCode.MY]: 'Malaisie',
  [TuiCountryIsoCode.MZ]: 'Mozambique',
  [TuiCountryIsoCode.NA]: 'Namibie',
  [TuiCountryIsoCode.NC]: 'Nouvelle-Calédonie',
  [TuiCountryIsoCode.NE]: 'Niger',
  [TuiCountryIsoCode.NG]: 'Nigéria',
  [TuiCountryIsoCode.NI]: 'Nicaragua',
  [TuiCountryIsoCode.NL]: 'Pays-Bas',
  [TuiCountryIsoCode.NO]: 'Norvège',
  [TuiCountryIsoCode.NP]: 'Népal',
  [TuiCountryIsoCode.NZ]: 'Nouvelle-Zélande',
  [TuiCountryIsoCode.OM]: 'Oman',
  [TuiCountryIsoCode.PA]: 'Panama',
  [TuiCountryIsoCode.PE]: 'Pérou',
  [TuiCountryIsoCode.PF]: 'Polynésie française',
  [TuiCountryIsoCode.PG]: 'Papouasie-Nouvelle-Guinée',
  [TuiCountryIsoCode.PH]: 'Philippines',
  [TuiCountryIsoCode.PK]: 'Pakistan',
  [TuiCountryIsoCode.PL]: 'Pologne',
  [TuiCountryIsoCode.PT]: 'Portugal',
  [TuiCountryIsoCode.PW]: 'Palaos',
  [TuiCountryIsoCode.PY]: 'Paraguay',
  [TuiCountryIsoCode.QA]: 'Qatar',
  [TuiCountryIsoCode.RE]: 'La Réunion',
  [TuiCountryIsoCode.RO]: 'Roumanie',
  [TuiCountryIsoCode.RS]: 'Serbie',
  [TuiCountryIsoCode.RU]: 'Russie',
  [TuiCountryIsoCode.RW]: 'Rwanda',
  [TuiCountryIsoCode.SA]: 'Arabie Saoudite',
  [TuiCountryIsoCode.SB]: 'Iles Salomon',
  [TuiCountryIsoCode.SC]: 'Seychelles',
  [TuiCountryIsoCode.SD]: 'Soudan',
  [TuiCountryIsoCode.SE]: 'Suède',
  [TuiCountryIsoCode.SG]: 'Singapour',
  [TuiCountryIsoCode.SH]: 'Sainte-Hélène',
  [TuiCountryIsoCode.SI]: 'Slovénie',
  [TuiCountryIsoCode.SK]: 'Slovaquie',
  [TuiCountryIsoCode.SL]: 'Sierra Leone',
  [TuiCountryIsoCode.SM]: 'Saint-Marin',
  [TuiCountryIsoCode.SN]: 'Sénégal',
  [TuiCountryIsoCode.SO]: 'Somalie',
  [TuiCountryIsoCode.SR]: 'Suriname',
  [TuiCountryIsoCode.ST]: 'Sao Tomé-et-Principe',
  [TuiCountryIsoCode.SV]: 'El Salvador',
  [TuiCountryIsoCode.SX]: 'Saint-Martin',
  [TuiCountryIsoCode.SY]: 'République arabe syrienne',
  [TuiCountryIsoCode.SZ]: 'Swaziland',
  [TuiCountryIsoCode.TC]: 'Iles Turks-et-Caïcos',
  [TuiCountryIsoCode.TD]: 'Tchad',
  [TuiCountryIsoCode.TG]: 'Togo',
  [TuiCountryIsoCode.TH]: 'Thaïlande',
  [TuiCountryIsoCode.TJ]: 'Tadjikistan',
  [TuiCountryIsoCode.TL]: 'Timor-Leste',
  [TuiCountryIsoCode.TM]: 'Turkménistan',
  [TuiCountryIsoCode.TN]: 'Tunisie',
  [TuiCountryIsoCode.TO]: 'Tonga',
  [TuiCountryIsoCode.TR]: 'Turquie',
  [TuiCountryIsoCode.TT]: 'Trinité-et-Tobago',
  [TuiCountryIsoCode.TW]: 'Taïwan',
  [TuiCountryIsoCode.TZ]: 'Tanzanie',
  [TuiCountryIsoCode.UA]: 'Ukraine',
  [TuiCountryIsoCode.UG]: 'Ouganda',
  [TuiCountryIsoCode.US]: "États-Unis d'Amérique",
  [TuiCountryIsoCode.UY]: 'Uruguay',
  [TuiCountryIsoCode.UZ]: 'Ouzbékistan',
  [TuiCountryIsoCode.VC]: 'Saint-Vincent-et-les-Grenadines',
  [TuiCountryIsoCode.VE]: 'Venezuela',
  [TuiCountryIsoCode.VG]: 'Iles vierges britanniques',
  [TuiCountryIsoCode.VN]: 'Vietnam',
  [TuiCountryIsoCode.VU]: 'Vanuatu',
  [TuiCountryIsoCode.WS]: 'Samoa',
  [TuiCountryIsoCode.XK]: 'Kosovo',
  [TuiCountryIsoCode.YE]: 'Yémen',
  [TuiCountryIsoCode.YT]: 'Mayotte',
  [TuiCountryIsoCode.ZA]: 'Afrique du Sud',
  [TuiCountryIsoCode.ZM]: 'Zambie',
  [TuiCountryIsoCode.ZW]: 'Zimbabwe'
};
const TUI_FRENCH_LANGUAGE_CORE = {
  months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  close: 'Fermer',
  nothingFoundMessage: 'Aucun résultat',
  defaultErrorMessage: 'Valeur non valide',
  spinTexts: ['Précédent', 'Suivant'],
  shortWeekDays: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
  countries: TUI_FRENCH_LANGUAGE_COUNTRIES
};
const TUI_FRENCH_LANGUAGE_KIT = {
  cancel: 'Annuler',
  done: 'Terminé',
  more: 'Plus',
  otherDate: 'Autre date...',
  showAll: 'Tout afficher',
  hide: 'Cacher',
  mobileCalendarTexts: ['Choisir un jour', 'Choisir un intervalle', 'Choisissez des jours'],
  range: ['De', 'À'],
  countTexts: ['Plus', 'Moins'],
  time: {
    'HH:MM': 'HH:MM',
    'HH:MM:SS': 'HH:MM:SS',
    'HH:MM:SS.MSS': 'HH:MM:SS.MSS'
  },
  dateTexts: {
    DMY: 'jj.mm.aaaa',
    MDY: 'mm.jj.aaaa',
    YMD: 'aaaa.mm.jj'
  },
  digitalInformationUnits: ['B', 'KiB', 'MiB'],
  passwordTexts: ['Afficher le mot de passe', 'Cacher le mot de passe'],
  copyTexts: ['Copier', 'Copié'],
  shortCalendarMonths: ['Janv', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
  pagination: ['Page précédente', 'Page suivante'],
  fileTexts: {
    loadingError: 'Erreur de chargement',
    preview: 'Aperçu',
    remove: 'Supprimer'
  },
  inputFileTexts: {
    defaultLabelSingle: 'ou déposez-le ici',
    defaultLabelMultiple: 'ou déposez-les ici',
    defaultLinkSingle: 'Sélectionnez un fichier',
    defaultLinkMultiple: 'Sélectionnez des fichiers',
    maxSizeRejectionReason: 'Le fichier est trop volumineux',
    formatRejectionReason: 'Format de fichier incorrect',
    drop: 'Déposez le fichier ici',
    dropMultiple: 'Déposez les fichiers ici'
  },
  multiSelectTexts: {
    all: 'Tout sélectionner',
    none: 'Ne rien sélectionner'
  },
  prompt: {
    yes: 'Oui',
    no: 'Non'
  }
};
const TUI_FRENCH_LANGUAGE = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({
  name: 'french'
}, TUI_FRENCH_LANGUAGE_CORE), TUI_FRENCH_LANGUAGE_KIT), TUI_FRENCH_LANGUAGE_ADDON_TABLE), TUI_FRENCH_LANGUAGE_ADDON_COMMERCE), TUI_FRENCH_LANGUAGE_ADDON_EDITOR), TUI_FRENCH_LANGUAGE_ADDON_PREVIEW);

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_FRENCH_LANGUAGE, TUI_FRENCH_LANGUAGE_ADDON_COMMERCE, TUI_FRENCH_LANGUAGE_ADDON_EDITOR, TUI_FRENCH_LANGUAGE_ADDON_PREVIEW, TUI_FRENCH_LANGUAGE_ADDON_TABLE, TUI_FRENCH_LANGUAGE_CORE, TUI_FRENCH_LANGUAGE_COUNTRIES, TUI_FRENCH_LANGUAGE_KIT };
